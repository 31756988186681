<template>
  <v-card class="mx-auto pa-5">
    <v-row>
      <v-col cols="12" sm="6" class="pl-1 pr-2">
        <h3 class="title-col mb-2 pl-3">Thông tin chung</h3>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Dịch Vụ
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-select v-model="item.service_id" :disabled="checkRoleHasEdit('service_id')" :items="services" class="select-status" dense item-text="name" item-value="id" outlined></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Tên hóa đơn
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="item.name" :disabled="checkRoleHasEdit('name')" dense outlined
                          placeholder="(Giúp bạn dễ nhớ, dễ tìm đơn hàng)"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Giá trị đơn hàng (USD) <div class="red--text">*</div></v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field placeholder="đơn vị USD" dense outlined
                          v-model="item.price"
                          :disabled="checkRoleHasEdit('price')"
                          v-currency="{
                                locale: 'en-US',
                                currency: 'USD',
                                valueRange: { min: 0 },
                                allowNegative: false
                            }"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="UserRole === CONSTANTS.ROLES.SUPER_ADMINISTRATOR">
          <v-col cols="4">
            <v-subheader>Tracking Number
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="item.tracking_number" dense outlined placeholder=""></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Bảo hiểm hàng hóa
            </v-subheader>
          </v-col>
          <v-col cols="8" style="font-size: 1rem;">
            <v-switch v-model="item.is_insurance" :disabled="checkRoleHasEdit('is_insurance')" label="Nếu mua bảo hiểm hàng hóa, hệ thống sẽ tính phí 10% số tiền dựa theo giá trị đơn hàng"></v-switch>
            <!--            <v-tooltip bottom v-if="item.note">
                          <template v-slot:activator="{ on, attrs }">

                          </template>
                          <span>Nếu mua bảo hiểm hàng hóa, hệ thống sẽ tính phí 10% số tiền dựa theo giá trị đơn hàng</span>
                        </v-tooltip>-->

          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Địa chỉ trả hàng</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="item.return_address" dense outlined :disabled="checkRoleHasEdit('return_address')"
                          placeholder="Địa chỉ nhận lại nếu không giao hàng thành công"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Ghi chú</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-textarea v-model="item.note" :disabled="checkRoleHasEdit('note')" :rows="2" dense outlined></v-textarea>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Ghi chú của khách hàng</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-textarea v-model="item.customer_note" :disabled="checkRoleHasEdit('customer_note')" :rows="2" dense outlined></v-textarea>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" class="pl-2 pr-1">
        <h3 class="title-col mb-2 pl-3">Thông tin người nhận</h3>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Tên người nhận
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="item.receiver_name" :disabled="checkRoleHasEdit('receiver_name')" dense outlined></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Số điện thoại</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="item.receiver_phone" :disabled="checkRoleHasEdit('receiver_phone')" maxlength="50" dense outlined></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Địa chỉ nhận hàng
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-textarea v-model="item.receiver_address" :disabled="checkRoleHasEdit('receiver_address')" :rows="2" dense outlined
                        placeholder="Chỉ gồm tên đường số nhà. Ví dụ: 520 Meador Dr Apt B"></v-textarea>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4"></v-col>
          <v-col cols="8">
            <v-row no-gutters>
              <v-col cols="12" md="5">
                <v-text-field v-model="item.receiver_city" :disabled="checkRoleHasEdit('receiver_city')" dense label="Thành phố *"
                              outlined></v-text-field>
                <v-text-field v-model="item.receiver_state" :disabled="checkRoleHasEdit('receiver_state')" dense label="Bang *" outlined></v-text-field>
              </v-col>
              <v-col cols="12" md="5" offset-md="2">
                <v-text-field v-model="item.receiver_post_code" :disabled="checkRoleHasEdit('receiver_post_code')" dense label="Mã bưu điện *"
                              outlined></v-text-field>
                <v-autocomplete v-model="item.receiver_country_id" :disabled="checkRoleHasEdit('receiver_country_id')" :items="countries" dense item-text="name"
                                item-value="id"
                                label="Quốc gia*" outlined>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Ghi chú</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-textarea v-model="item.receiver_note" :disabled="checkRoleHasEdit('receiver_note')" :rows="2" dense outlined></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <hr class="mb-2">
    <h3 class="title-col mb-2 pl-3">Thông tin kiện hàng</h3>
    <v-row>
      <v-col cols="12" sm="5" lg="5">
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Nhận hàng vận chuyển từ</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-select
              v-model="item.income_shipment_id" :disabled="checkRoleHasEdit('income_shipment_id')" :items="incomeShipments"
              dense
              item-text="name"
              item-value="id" outlined>
            </v-select>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Mã vận đơn gửi đến</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="item.income_shipment_code" :disabled="checkRoleHasEdit('income_shipment_code')" dense outlined required></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Tên kiện hàng
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="item.package_name" :disabled="checkRoleHasEdit('package_name')" dense outlined></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Kho nhận hàng
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-select v-model="item.warehouse_id" :items="warehouses" :disabled="checkRoleHasEdit('warehouse_id')"
                      dense item-text="name" item-value="id" outlined></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Cước vận chuyển</v-subheader>
          </v-col>
          <v-col cols="8" class="py-3">
            <div v-if="isNaN(parseInt(shippingFee))">{{ shippingFee }}</div>
            <money-format v-else :value="parseInt(shippingFee)" style="display: inline-block;"
                          :hide-subunits=true :locale="'vi'" :currency-code="'VND'"></money-format>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="0" lg="1"></v-col>
      <v-col cols="12" sm="5" lg="5">
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Chiều dài (cm)
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field placeholder="cm" v-model="item.length" :disabled="checkRoleHasEdit('length')" dense outlined type="number"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Chiều rộng (cm)
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field placeholder="cm" v-model="item.width" :disabled="checkRoleHasEdit('width')" dense outlined type="number"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Chiều cao (cm)
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field placeholder="cm" v-model="item.height" :disabled="checkRoleHasEdit('height')" dense outlined type="number"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Cân nặng (g)
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field placeholder="gram" v-model="item.weight" :disabled="checkRoleHasEdit('weight')" dense min=0 outlined type="number"></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <hr class="mb-2">
    <h3 class="title-col mb-2">
      Chi tiết hàng hóa
      <v-tooltip v-if="!checkRoleHasEdit('disable')" top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" style="font-size: 2.25rem;margin-left: 1rem;"
                  v-on="on"
                  @click="addOrderItem" color="success">mdi-plus
          </v-icon>
        </template>
        <span>Ấn vào đây để thêm hàng hóa</span>
      </v-tooltip>
    </h3>
    <v-row>
      <v-col v-for="(orderItem, idx) in item.order_sync_items" :key="idx" cols="12" sm="4">
        <UpdateOrderItem :disableField="checkRoleHasEdit('disable')" :selectedItem="orderItem" :idx="idx" @removeOrderItem="removeOrderItem"></UpdateOrderItem>
      </v-col>
    </v-row>
    <hr class="mb-2 mt-2">
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="processUpdate">{{ !isBatchEdit ? 'Update' : 'Batch Edit' }}</v-btn>
      <v-btn v-if="!isBatchEdit" color="success" text @click="onClickPublishOrder">Publish Order</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import UpdateOrderItem from "./Update/UpdateOrderItem";
import MoneyFormat from "vue-money-format";

export default {
  name: 'UpdateOrder',
  components: {UpdateOrderItem, MoneyFormat},
  props: ['selectedItem', 'onUpdated', 'onCancel', 'dialogUpdate', 'isBatchEdit', 'pickedItems'],
  data: () => ({
    item: {},
    listOrderItems: [
      {},
      {}
    ],
    shippingFee: '',
    roleReceiverAllowedEdit: ['length', 'width', 'height', 'weight'],
  }),
  computed: {
    ...mapGetters({
      services: 'service/all',
      countries: 'country/all',
      incomeShipments: 'income_shipment/all',
      warehouses: 'warehouse/all',
    }),
    paramsToGetShippingFee() {
      return {
        length: this.item.length,
        width: this.item.width,
        height: this.item.height,
        weight: this.item.weight,
        rank: this.item.customer.rank_id,
        service_id: this.item.service_id,
        country: this.item.receiver_country_id,
        is_insurance: this.item.is_insurance,
        price: this.item.price ? this.item.price.replace('$', '') : null,
      }
    }
  },
  methods: {
    ...mapActions({
      updateItem: 'ordersync/update',
      batchEdit: 'ordersync/batchEdit',
      calcShippingFees: 'order/calcShippingFees',
      publishOrder: 'ordersync/publishOrder',
    }),
    processUpdate() {
      let itemToUpdate = {
        ...this.item,
        price: this.item.price ? this.item.price.replace('$', '') : ''
      };
      if (itemToUpdate.customer) delete itemToUpdate.customer;
      if (this.UserRole === this.CONSTANTS.ROLES.RECEIVER) {
        itemToUpdate = {
          id: this.item.id,
          length: this.item.length,
          width: this.item.width,
          height: this.item.height,
          weight: this.item.weight,
        };
      }
      if (this.isBatchEdit) {
        itemToUpdate.order_ids = this.pickedItems.map(i => i.id);
        this.batchEdit(itemToUpdate).then(res => {
          this.onUpdated(res);
          this.noticeSuccess('Success');
          this.onCancel();
        });
      } else {
        this.updateItem(itemToUpdate).then(res => {
          this.onUpdated(res);
          this.noticeSuccess('Order updated');
          this.onCancel();
        });
      }
    },
    onClickPublishOrder() {
      const data = JSON.parse(JSON.stringify(this.item))
      data.order_sync_id = data.id;
      data.price = this.item.price ? this.item.price.replace('$', '') : ''
      data.order_items = data.order_sync_items;
      if (data.customer) delete data.customer;
      if (data.order_sync_items) delete data.order_sync_items;
      if (data.raw_data) delete data.raw_data;
      if (data.tracking_number) delete data.tracking_number;
      delete data.id;
      this.publishOrder(data).then(() => {
        this.onCancel();
      });
    },
    checkRoleHasEdit(field) {
      // if (this.UserRole === this.CONSTANTS.ROLES.RECEIVER && this.$router && this.$router.currentRoute && this.$router.currentRoute.name === 'ScanOrder') {
      if (this.UserRole === this.CONSTANTS.ROLES.RECEIVER) {
        if (this.roleReceiverAllowedEdit.includes(field)) {
          return false
        } else return true;
      }
      return false;
    },
    addOrderItem() {
      this.item.order_sync_items.push({
        name: '',
        link: '',
        price: '',
        quantity: ''
      })
    },
    removeOrderItem(e) {
      console.log('e', e, this.item.order_sync_items)
      if (this.item.order_sync_items.length > 1) {
        this.item.order_sync_items.splice(e, 1)
      }
    },
  },
  watch: {
    selectedItem: {
      handler() {
        if (this.selectedItem) this.item = JSON.parse(JSON.stringify(this.selectedItem));
      },
      deep: true,
      immediate: true
    },
    dialogUpdate: {
      handler(newVal) {
        if (newVal && this.selectedItem) {
          this.item = JSON.parse(JSON.stringify(this.selectedItem));
        }
      },
      deep: true,
      immediate: true
    },
    paramsToGetShippingFee: {
      async handler(val) {
        if (val.rank < 0) return this.shippingFee = 'Lỗi nhận diện khách hàng';
        let missingFields = [];
        if (!val.length) missingFields.push('chiều dài');
        if (!val.width) missingFields.push('chiều rộng');
        if (!val.height) missingFields.push('chiều cao');
        if (!val.weight) missingFields.push('cân nặng');

        if (!val.service_id) missingFields.push('dịch vụ');
        if (!val.country) missingFields.push('quốc gia');

        if (val.is_insurance === true) {
          if (!val.price) return this.shippingFee = 'Thiếu giá trị đơn hàng';
        }
        if (missingFields.length > 0) return this.shippingFee = 'Thiếu ' + missingFields.join(', ')

        if (this.item.service_id === this.CONSTANTS.SERVICE_TYPE.SHIP_EXPRESS) {
          return this.shippingFee = 'Dịch vụ này sẽ tính giá khi nhận hàng';
        }

        let result = await this.calcShippingFees({
          ...val,
          silent: true
        });
        let shippingFee = result.data;
        if (shippingFee.error) this.shippingFee = shippingFee.message;
        else {
          let totalFee = parseInt(shippingFee.data.total);
          if (totalFee < 1 || isNaN(totalFee)) {
            this.shippingFee = 'Không tìm được giá';
          } else this.shippingFee = totalFee;
        }
      },
      deep: true,
      immediate: true
    },
  }
}
</script>
