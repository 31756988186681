<template>
  <v-card :elevation="1" style="border: 1px solid #aaa;">
    <v-card-title class="px-3 py-0 mb-3">
      <v-btn class="btn-package closed" icon color="danger">
        <v-icon light @click="remove">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-row class="px-3" no-gutters>
      <v-col cols="12">
        <v-text-field v-model="selectedItem.name" :disabled="disableField" dense label="Tên hàng hóa và chất liệu*" outlined></v-text-field>
        <v-text-field v-model="selectedItem.link" :disabled="disableField" dense label="Link mô tả hàng hóa" outlined></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field v-model="selectedItem.price" :disabled="disableField" placeholder="đơn vị USD" dense label="Giá trị hàng" outlined type="number"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field v-model="selectedItem.quantity" :disabled="disableField" class="ml-2" dense label="Số lượng" outlined type="number" min="1"></v-text-field>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: 'UpdateOrderItem',
  props: {
    selectedItem: {
      default: {},
    },
    idx: {
      default: '',
    },
    disableField: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    item: {}
  }),
  methods: {
    remove(){
      this.$emit('removeOrderItem', this.idx)
    }
  },
  watch: {
    selectedItem: {
      handler() {
        if (this.selectedItem) this.item = this.selectedItem;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style scoped>
.closed {
  position: absolute;
  right: -1rem;
  background: var(--v-error-lighten1);
  color: #fff !important;
  width: 2rem;
  height: 2rem;
}
</style>
